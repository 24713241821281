@import '../../styles/index';

.item {
  margin-bottom: 2rem;

  @include space-between-elements();

  @include media-from(sm) {
    &:first-child {
      margin-right: 2rem;
    }
  }

  & + & {
    margin-top: 0;

    &:nth-child(2n):not(:last-child) {
      margin-bottom: $space-between-split-section-items;

      @include media-from('sm') {
        margin-bottom: $space-between-split-section-items-sm;
      }
    }
  }
}
