@import '../../styles/index';

.base {
  --gutter-size: 2rem;
  display: flex;
  margin-left: calc(-1 * var(--gutter-size));
  width: auto;
}

.column {
  background-clip: padding-box;
  padding-left: var(--gutter-size);

  > * {
    margin-bottom: var(--gutter-size);
    word-wrap: break-word;
    hyphens: auto;

    p,
    span {
      word-break: normal;
      hyphens: none;
    }
  }
}
