@import '../../styles/index';

.search-headline-container {
  margin: 1rem auto 2rem auto !important;

  @include media-from(sm) {
    margin: 2rem auto 2rem auto !important;
  }
}

.search-headline {
  font-size: $font-size-24;
  font-weight: $font-weight-bold;

  @include media-from(sm) {
    font-size: $font-size-28;
    padding-left: 1rem;
  }
}

.section {
  flex: 1;
}
