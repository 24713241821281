@import '../../styles/index';

.base {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.is-overlay {
  opacity: 0.9;
}

.blue {
  background-color: $blue-2;
}

.green {
  background-color: $green-2;
}

.orange {
  background-color: $orange-2;
}

.red {
  background-color: $red-2;
}

.surrogate {
  background-color: $surrogate-9;
}

.surrogate-light {
  background-color: $surrogate-2;
}

.turquoise {
  background-color: $turquoise-2;
}

.violet {
  background-color: $violet-2;
}

.white {
  background-color: $neutral-0;
}

.yellow {
  background-color: $yellow-2;
}
