@import '../../styles/index';

.base {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: -1.5rem;

  & > * {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  &.teaser-tile-large {
    margin-bottom: -1.25rem;
    & > * {
      margin-bottom: 1.25rem;
    }
  }
}

@include media-from(xs) {
  .teaser-tile-large {
    flex-direction: row;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    &.cols-three {
      & > * {
        width: 50%;
        padding: 0 0.5rem;
      }
    }
  }
}

@include media-from(sm) {
  .base {
    margin-bottom: -4rem;

    & > * {
      margin-bottom: 4rem;
    }
  }

  .cols-two,
  .cols-three {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    & > * {
      padding: 0 1.5rem;
    }
  }

  .cols-two {
    & > * {
      width: 50%;
    }
  }
  .default {
    flex-direction: row;
    &.cols-three {
      & > * {
        width: 33.3333%;
      }
    }
  }
  .teaser-tile-large {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -1.5rem;
    &.cols-three {
      & > * {
        padding: 0 1rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

@include media-from(lg) {
  .teaser-tile-large {
    &.cols-three {
      & > * {
        width: 33.3333%;
      }
    }
  }
}
