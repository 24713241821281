@import '../../styles/index';

.base {
  align-items: center;
  color: $surrogate-9;
  display: inline-flex;
  gap: 0.5rem;
  font-size: $font-size-18;
  font-weight: $font-weight-medium;

  @include media-to(lg) {
    span {
      display: none;
    }
  }
}
