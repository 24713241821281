@import '../../../styles/index';

.base {
  position: relative;
  background-color: $neutral-0;
  box-shadow: $shadow-regio-tariff-card;

  @include media-from(sm) {
    flex: 50%;
    max-width: 50%;
  }

  div:last-child {
    margin-bottom: 0;
  }
}

.default {
  padding: 2.5rem 1.5rem;
  z-index: 1;

  @include media-from(sm) {
    padding: 3.75rem 3rem;
  }
}

.small {
  padding: 2rem 1.5rem 1rem 1.5rem;

  @include media-from(sm) {
    padding: 2.5rem 2rem 1.25rem 2rem;
    margin: 5.5rem 0 auto 0;
  }
}

.logo {
  display: block;
  margin: 0 auto;
  max-width: 7rem;
  width: 100%;
  margin-bottom: 1.5rem;

  @include media-from(sm) {
    max-width: 11.375rem;
    margin-bottom: 1.7rem;
  }
}
