@import '../../styles/index';

.list {
  margin: 0;
  padding: 0;
}

.border-between > *:not(:last-of-type) {
  border-bottom: 1px solid $neutral-7;
}

.alternate-background > :nth-child(odd) {
  background: $neutral-4;
}
