@import '../../styles/index';

.list {
  display: grid;
  position: relative;
  gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  > * {
    word-wrap: break-word;
    hyphens: auto;

    p,
    span {
      word-break: normal;
      hyphens: none;
    }
  }

  &.no-equal-cols {
    display: flex;
    flex-wrap: wrap;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.stretch {
      > * {
        flex-grow: 1;
      }
    }
  }

  @include media-from(sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &.one-col {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @include media-from(md) {
    &.two-cols {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.three-cols {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &.four-cols {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.left {
  justify-items: start;
}

.center {
  justify-items: center;
}

.right {
  justify-items: end;
}

.stretch {
  justify-items: stretch;
}
