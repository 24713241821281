@import '../../styles/index';

.base {
  border-radius: $border-radius-default;
  color: $neutral-12;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: start;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.headline {
  display: block;
  font-size: $font-size-22;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 0;

  @include media-from(sm) {
    font-size: $font-size-24;
  }

  p,
  u,
  em,
  strong {
    font-style: normal;
    font-weight: $font-weight-medium;
    margin-block-end: 0;
    margin-block-start: 0;
    text-decoration: none;
  }
}

.content {
  font-size: $font-size-20;
  margin-top: 0.5rem;

  & > p {
    font-size: $font-size-18;
    font-weight: $font-weight-light;
    line-height: 1.6;
    margin: 0;

    @include media-from(sm) {
      font-size: $font-size-20;
    }
  }
}

.link {
  font-size: $font-size-18;
  line-height: 1;
  padding: 1.25rem 0;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}

.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .link {
    margin: 0 auto;
    transition: padding-left $duration-default;

    &:hover {
      margin-left: auto;
      padding-left: 0.75rem;
    }
  }
}

.icon-wrapper {
  height: 4rem;
  margin-bottom: 1rem;
  width: 4rem;
}

.icon-wrapper:empty {
  background: lightgray;
}

.icon {
  color: $surrogate-9;
  height: 100%;
  width: 100%;
}

[data-layout-background='gradient'],
[data-bg-color='gradient'] {
  .icon,
  .base,
  .link {
    color: $neutral-0;
  }
}
