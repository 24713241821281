@import '../../styles/index';

$columnMarginSizes: 0 0.5 0.75 1 1.125 1.25 1.5 2 3;
$bottomMarginSizes: 0 0.5 0.75 1 1.125 1.25 1.5 2 3;
$sizeKeys: xs sm md lg;

$factor: 1000;

.full-width-children {
  & > * {
    width: 100%;
  }
}

.base {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  position: relative;

  &--with-headline {
    display: block;
  }

  @include media-from(sm) {
    flex-direction: row;
  }
}

.headline {
  margin: 0 0 1.25rem 0;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  font-size: $font-size-20;

  @include media-from(sm) {
    font-size: $font-size-24;
  }

  .base + & {
    margin-top: 1rem;
  }
}

.title {
  font-size: $font-size-18;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  margin-bottom: 1rem;

  @include media-from(sm) {
    line-height: 1.6;
  }
}

.description {
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

.base {
  @each $columnMarginSize in $columnMarginSizes {
    &.column-margin-sizedefault#{$columnMarginSize * $factor} {
      & > *:not(:last-child) {
        margin-right: #{$columnMarginSize}rem;
      }
    }
  }

  @each $sizeKey in $sizeKeys {
    @include media-from($sizeKey) {
      @each $columnMarginSize in $columnMarginSizes {
        &.column-margin-size#{$sizeKey}#{$columnMarginSize * $factor} {
          & > *:not(:last-child) {
            margin-right: #{$columnMarginSize}rem;
          }
        }
      }
    }
  }

  @each $bottomMarginSize in $bottomMarginSizes {
    &.bottom-margin-sizedefault#{$bottomMarginSize * $factor} {
      & > * {
        margin-bottom: #{$bottomMarginSize}rem;
      }
    }
  }

  @each $sizeKey in $sizeKeys {
    @include media-from($sizeKey) {
      @each $bottomMarginSize in $bottomMarginSizes {
        &.bottom-margin-size#{$sizeKey}#{$bottomMarginSize * $factor} {
          & > * {
            margin-bottom: #{$bottomMarginSize}rem;
          }
        }
      }
    }
  }
}
