@import '../../styles/index';

.base {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  user-select: none;

  @include media-from(sm) {
    align-items: center;
  }
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-28;
  line-height: 1.3;
  margin: -0.5rem 0 0.5rem 0;
  width: fit-content;

  @extend %text-linear-gradient;

  @include media-from(sm) {
    font-size: $font-size-36;
    margin-bottom: 0.75rem;
  }
}

.sub-title {
  font-size: $font-size-20;
  font-weight: $font-weight-light;
  margin: 0;
}
