.video {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  &[data-vimeo-initialized] {
    opacity: 1;
    z-index: 1;
  }
}
