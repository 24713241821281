@import '../../styles/index';

.stage-box {
  margin-bottom: 0 !important;
  @include media-from(xs) {
    margin-bottom: 5rem;
  }
  @include media-from(lg) {
    margin-bottom: 0;
  }
}

.benefits-headline,
.related-jobs-headline {
  margin-bottom: 2.5rem !important;
}

@include media-from(sm) {
  .benefits-headline,
  .related-jobs-headline {
    margin-bottom: 4rem !important;
  }
}

.apply-headline {
  max-width: 900px;
  padding: 0 1rem;

  @include media-from(sm) {
    padding: 0 2rem;
  }

  div {
    font-size: $font-size-16;
    @include media-from(xs) {
      font-size: $font-size-22;
    }
  }
}

.interest-section {
  padding: 3rem 0 !important;

  @include media-from(sm) {
    padding: 5rem 0 !important;
  }
}

.section-spacer {
  margin-top: 4rem !important;
  padding-bottom: 2rem;

  @include media-from(sm) {
    margin-top: 10rem !important;
  }
}

.jobs-headline {
  max-width: calc(100% - 2rem);
}

.question-container {
  width: 100%;
}

.button {
  margin: 0;

  @include media-from(md) {
    padding-left: 2rem;
    padding-right: 2rem;
    width: auto;
  }
}

.modal-button-wrapper {
  @include media-to(md) {
    // we need important here to avoid 0 from container
    > a:first-child {
      margin-bottom: 1rem !important;
    }
  }

  // TODO: this important is needed for overrides from duplicated styles @todo
  @include media-from(md) {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;

    > a:first-child {
      margin-right: 1rem !important;
    }
  }
}

.awards {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  width: 100%;

  @include media-from(sm) {
    justify-content: space-between;
  }

  img {
    height: 100%;
    max-width: 50%;

    @include media-from(xs) {
      max-width: 25%;
    }
  }
}
