@use 'sass:math';

@import '../../styles/index';

.base,
.wrapper {
  position: relative;
}

.wrapper-large {
  @include media-from(lg) {
    padding-bottom: 10rem;
  }
}

.wrapper-small {
  padding-bottom: 3rem;
}

.stagebox {
  position: relative;

  @include media-to(xs) {
    margin-top: -1px;
  }
}

.stagebox-in-regular {
  width: 100%;

  @include media-from(xs) {
    margin-top: calc(min(10rem, calc(10 / (1248 / 16) * 100vw)) * -1);
    width: unset;
  }

  @include media-from(lg) {
    bottom: 0;
    margin-top: 0;
    position: absolute;
    width: 100%;

    & > form > section,
    & > section {
      border-right-color: transparent;
    }
  }
}

.stagebox-in-small {
  @include media-from(xs) {
    margin-top: -4rem;
  }

  @include media-from(sm) {
    margin-top: -6rem;
  }

  @include media-from(md) {
    margin-top: -8rem;
  }
}

.section {
  position: relative;
  z-index: 0;

  &,
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @include media-to(xs) {
    &::after {
      display: none;
    }
  }
}

.title-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 50%;
  position: absolute !important; // TODO: important is used for duplicated styles nbank
  top: 0;
  transform: translateX(-50%);
  z-index: 2;
}

.badge-wrapper {
  display: block;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 1;

  @include media-from(xs) {
    aspect-ratio: 2 / 1;
    position: absolute;
  }
}

.badge {
  hyphens: auto;
  overflow-wrap: break-word;
  padding-left: calc(0.5rem + env(safe-area-inset-left));
  padding-right: calc(0.5rem + env(safe-area-inset-right));
  position: relative;
  top: 0;
  z-index: 1;

  @include media-from(xs) {
    position: absolute;
    rotate: -6deg;
  }

  @include media-from(lg) {
    margin: 1rem;
    scale: 1.25;
  }

  &-top-left {
    @include media-from(xs) {
      left: 0.75rem;
      top: 0.75rem;
    }

    @include media-from(sm) {
      left: 1rem;
      top: 1rem;
    }
  }

  &-top-right {
    @include media-from(xs) {
      right: 0.75rem;
      top: 0.75rem;
    }

    @include media-from(sm) {
      right: 1rem;
      top: 1rem;
    }
  }

  &-bottom-right {
    @include media-from(xs) {
      bottom: 0;
      left: auto;
      right: 1rem;
      top: auto;
      translate: 0 50%;
    }

    .skewedBottom & {
      @include media-from(xs) {
        $skew-shift: 126px;
        $responsive-skew-shift: min(
          calc(math.div($skew-shift, map-get($breakpoints, 'xl')) * 100vw),
          $skew-shift
        );

        bottom: $responsive-skew-shift;
        translate: 0 50%;
      }
    }

    .with-stage-box.skewedBottom & {
      @include media-from(xs) {
        right: 0rem;
      }

      @include media-from(sm) {
        right: 1rem;
      }
    }
  }
}
