@import '../../styles/index';

.base {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.default {
  background-image: linear-gradient(90deg, $violet-9, $red-9);

  &.is-overlay {
    background-image: linear-gradient(
      90deg,
      rgba($violet-9, 0.9),
      rgba($red-9, 0.9)
    );
  }
}

.magazine {
  background-image: linear-gradient(78.7deg, #b1d995 3.27%, #3cb2b8 93.19%);

  &.is-overlay {
    background-image: linear-gradient(
      78.7deg,
      rgba(#b1d995, 0.8) 3.27%,
      rgba(#3cb2b8, 0.8) 93.19%
    );
  }
}

.mein-h-gas {
  background-image: linear-gradient(
    120deg,
    $orange-9 15%,
    $red-9 30%,
    $violet-9 90%
  );

  &.is-overlay {
    background-image: linear-gradient(
      120deg,
      rgba($orange-9, 0.9) 15%,
      rgba($red-9, 0.9) 30%,
      rgba($violet-9, 0.9) 90%
    );
  }
}
