@import '../../styles/index';

.base {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0 2.5rem 0;
  align-items: center;

  @include media-from('xs') {
    flex-direction: row;
  }

  @include media-from('xl') {
    padding: 3.5rem 0 3rem 0;
  }
}

.link {
  font-weight: $font-weight-light;
  color: $neutral-12;
  font-size: $font-size-16;
  line-height: 1.5rem;
  padding: 0.25rem 0.5rem;

  @include media-from(xs) {
    padding: 0;

    &:not(:first-child) {
      margin-left: 2rem;
    }
  }

  @include media-from(md) {
    line-height: 1.375;
  }
}
