@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  padding-top: 3.75rem;

  @include media-from(sm) {
    flex-direction: row;
    padding-top: 0;
    padding-right: 5.1875rem;
  }
}

.button {
  margin-top: 3rem;

  @include media-from(sm) {
    margin-top: 2.75rem;
  }
}

.detail-link {
  display: block;
  border: none;
  border-bottom: 2px solid $surrogate-9;
  background: none;
  color: $surrogate-9;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  margin: auto;
  padding: 0;
  cursor: pointer;

  @include media-from(sm) {
    font-size: $font-size-18;
  }
}

.badge {
  position: absolute;
  top: 0;
  right: 0.75rem;
  transform: translateY(-50%);

  @include media-from(sm) {
    right: 0;
    transform: translate(50%, -50%);
  }
}

.hide-on-mobile {
  display: none;

  @include media-from(sm) {
    display: flex;
  }
}

.show-on-mobile {
  display: flex;

  @include media-from(sm) {
    display: none;
  }
}
