@import '../../styles/index';

.base {
  align-items: center;
  display: flex;
  height: 0.875rem;
  justify-content: center;
  width: 1.125rem;
}

.element {
  position: relative;
  transition: background-color $duration-default;

  &, &::after, &::before {
    background-color: currentColor;
    border-radius: $border-radius-large;
    height: 0.125rem;
    width: 1rem;
  }

  &::after, &::before {
    content: "";
    display: block;
    left: 0;
    position: absolute;
    transform-origin: center;
    transition: transform $duration-default, top $duration-default $duration-default;
  }

  &::after {
    top: 0.375rem;
  }

  &::before {
    top: -0.375rem;
  }

  &.crossed {
    background-color: transparent;

    &::after {
      top: 0;
      transform: rotate(45deg);
      transition: top $duration-default, transform $duration-default $duration-default;
    }

    &::before {
      top: 0;
      transform: rotate(-45deg);
      transition: top $duration-default, transform $duration-default $duration-default;
    }
  }
}
