@import '../../styles/index';

.button {
  margin: 0;

  @include media-from(md) {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.modal-button-wrapper {
  @include media-to(md) {
    // we need important here to avoid 0 from container
    > a:first-child {
      margin-bottom: 1rem !important;
    }
  }

  // TODO: this important is needed for overrides from duplicated styles @todo
  @include media-from(md) {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;

    > a:first-child {
      margin-right: 1rem !important;
    }
  }
}
