@import '../../styles/index';

.base {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  & > div,
  > span {
    margin-left: 58px;

    & :last-child {
      margin-left: 0;
    }
    @include media-to(sm) {
      margin-left: 0;
    }
  }

  @include media-to(sm) {
    padding: 1rem;
  }
}

.netAmount {
  display: flex;
  font-size: $font-size-16;
  color: $neutral-9;
  margin-top: 16px;

  @include media-to(sm) {
    font-size: $font-size-16;
  }
}

.netAmountLabel {
  flex-grow: 1;
  margin-right: 26px;
}

.salesTax {
  display: flex;
  font-size: $font-size-16;
  color: $neutral-9;

  @include media-to(sm) {
    font-size: $font-size-14;
  }
}

.salesTaxLabel {
  flex-grow: 1;
  margin-right: 26px;
}

.grossAmount {
  display: flex;
  font-size: $font-size-22;
  font-weight: $font-weight-medium;

  @include media-to(sm) {
    font-size: $font-size-16;
  }
}

.grossAmountLabel {
  flex-grow: 1;
  margin-right: 26px;
}

.grossAmountValue {
  font-size: $font-size-22;

  @include media-to(sm) {
    margin-left: 0;
    font-size: $font-size-20;
  }
}
