@import '../../styles/index';

.search-headline-container {
  margin: 3rem auto 2rem auto !important;

  @include media-from(sm) {
    margin: 4rem auto 2rem auto !important;
  }
}

.search-result-container {
  margin: 0 -1rem 0;
  width: auto;

  @include media-from(sm) {
    margin: 0 auto;
    width: 100%;
  }
}

.search-headline {
  font-size: $font-size-24;
  font-weight: $font-weight-bold;

  @include media-from(sm) {
    font-size: $font-size-28;
    padding-left: 1rem;
  }
}

.filter-container {
  padding-top: 3rem !important;

  @include media-from(sm) {
    padding-top: 5rem !important;
  }
}

.searchbar-container {
  padding-bottom: 3rem !important;

  @include media-from(sm) {
    padding-bottom: 5rem !important;
  }
}

.job-alert-box {
  align-items: center;
  background-color: $surrogate-2;
  border: 2px dashed $surrogate-4;
  border-radius: $border-radius-default;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1.5rem;
  width: 100%;

  &-title {
    font-size: $font-size-20;
    font-weight: $font-weight-medium;
    margin: 0 0 0.25rem;
  }

  &-subtitle {
    font-size: $font-size-16;
    font-weight: $font-weight-light;
    margin-bottom: 1rem;
  }
}
