@import '../../styles/index';

.base {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter {
  max-width: none !important;
}

.results {
  margin-top: 1rem;
  gap: 1.5rem;
}
