@import '../../styles/index';

.base {
  margin: 0;
}

.link {
  color: currentColor;

  & + & {
    margin-left: 1rem;
  }
}
