@import '../../styles/index';

.base {
  position: relative;
  padding: 1rem;
  border-top: 5px solid $neutral-7;
  border-radius: 3px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background-color: $neutral-0;
  color: $neutral-12;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;

  &.is-first {
    border: none;
    background-color: $surrogate-9;
    color: $neutral-0;
  }
  &.is-active {
    border-color: $surrogate-9;
  }
  &.is-active.is-inverted {
    border-color: $red-9;
  }
}

.title,
.text {
  margin-block-start: 0;
  margin-block-end: 0;
}

.title {
  font-weight: $font-weight-bold;
  font-size: $font-size-18;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

.text {
  font-weight: $font-weight-light;
  font-size: $font-size-16;
  line-height: 1.375rem;
}

.icon {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.button-link {
  margin: 0.5rem 0 0.25rem;
}
