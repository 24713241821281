@import '../../styles/index';

.base {
  margin: 0;
}

.title {
  text-align: center;
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  line-height: 1.4;
  margin-top: 0;

  @include media-from(sm) {
    font-size: $font-size-44;
    line-height: 1.2;
    margin-bottom: 1.625rem;
  }
}

.subtitle {
  text-align: center;
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  line-height: 1.6;

  @include media-from(sm) {
    font-size: $font-size-22;
    margin-bottom: 5rem;
  }
}

.footnote {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 1.8;
  margin-top: 1.25rem;

  @include media-from(sm) {
    margin-top: 1.5rem;
  }
}

.footnote-date {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 1.8;
  margin: 0;
}

.product-overview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;

  @include media-from(sm) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }
}
