@import '../../styles/index';

.base {
  text-align: center;

  @include media-from(sm) {
    padding-top: 1rem;
  }
}

.icon {
  color: $surrogate-9;
  margin-bottom: 1rem;
}

.headline {
  font-weight: $font-weight-medium;
  font-size: $font-size-24;
  line-height: 1.3;
  margin-bottom: 0.75rem;
  margin-top: 0;

  @include media-from(sm) {
    font-size: $font-size-30;
    margin-bottom: 1rem;
  }
}

.subtext {
  font-size: $font-size-20;
  line-height: 1.3;
  color: $neutral-9;
  margin: 0;
}

.children-wrapper {
  display: flex;
  margin: 2rem auto 0;
  justify-content: center;
}
