@import '../../styles/index';

$heading-color: #686a6e;
$hr-color: #bcbdbc;

.base {
  display: grid;
  justify-items: center;
}

.heading {
  color: $heading-color;
  font-size: 1.75rem;
  margin: 0;
  font-weight: $font-weight-medium;
}

.score {
  color: rgba(0, 2, 16, 0.85);
  font-size: 6.5rem;
  margin: 0;
  font-weight: $font-weight-bold;
}

.hr {
  width: 14.5rem;
  margin-top: 1.5rem;
  border: 1px solid $hr-color;
  margin-bottom: 0;
}

.img {
  margin-top: 1.3rem;
}
