@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.375rem;
  height: 6.375rem;
  transform: rotate(15deg);
  color: $neutral-0;
  font-size: $font-size-12;
  text-align: center;
  border-radius: 50%;

  strong {
    display: block;
    font-size: $font-size-20;
    font-weight: $font-weight-bold;
    line-height: 1.2;

    @include media-from(sm) {
      font-size: $font-size-32;
    }
  }

  @include media-from(sm) {
    font-size: $font-size-18;
    width: 10.375rem;
    height: 10.375rem;
  }
}

.turquoise {
  background-color: $turquoise-9;
}

.green {
  background-color: $green-9;
}
