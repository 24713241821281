@import '../../styles/index';

.skewed-top,
.skewed-bottom {
  content: '';
  position: absolute;
  left: 0;
  height: 150px;
  width: 100%;
  transform: skewY(-5deg);
  background-color: white;
}

.skewed-top {
  top: -150px;
  transform-origin: bottom right;
}

.skewed-bottom {
  bottom: -150px;
  transform-origin: top left;
}

.skew-bg-white {
  background-color: $neutral-0;
}

.skew-bg-gray {
  background-color: $neutral-4;
}
