@import '../../../styles/magazine-index';

.base {
  max-width: 1280px;
  margin: 0 auto;
}

.inner-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(var(--current-magazine-color-rgb), 0.2);
  padding-top: 1.25rem;

  @include media-from(sm) {
    padding-top: 1.5rem;
    flex-direction: row;
  }
}

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.social {
  width: auto;
  margin-top: 0.5rem;
  color: rgba(var(--current-magazine-color-rgb), 0.4);

  @include media-from(sm) {
    margin-top: 0;
  }
}

.tag {
  margin: 0 0.75rem 0.75rem 0;
  cursor: default !important;
}

.info {
  margin-top: 1.25rem;
  font-style: italic;
  font-size: $font-size-14;
  line-height: 1.25;
  margin-bottom: 0;

  @include media-from(sm) {
    margin-top: 0.75rem;
  }
}
