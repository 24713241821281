@import '../../styles/index';

.tabs {
  background-color: $neutral-0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08), 0 0 64px 0 rgba(0, 0, 0, 0.08);
  transition: width $duration-default, margin-left $duration-default;
  border-radius: $border-radius-default;
  width: 100%;

  @include media-from(md) {
    padding: 2rem 1.5rem;
  }

  @include media-from('lg') {
    padding: 3rem 3rem 3.75rem;
  }

  &-no-shadow {
    box-shadow: none;
    padding: 0;
  }
}

.tab-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: relative;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  height: 5.5625rem;

  @include media-from(md) {
    scroll-snap-type: none;
    height: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &-align-right {
    .tab-nav-item:first-child {
      margin-left: auto;
    }
  }

  &-item {
    cursor: pointer;
    padding: 1rem 1.75rem;
    transition: background $duration-default, color $duration-default;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $neutral-9;
    box-sizing: content-box;
    width: 100%;
    flex-shrink: 0;
    scroll-snap-align: center;

    @include media-from(md) {
      width: unset;
      padding: 1rem 0;
    }

    &-icon {
      margin-left: 1rem;
      margin-right: -0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      position: relative;
    }

    &-headline {
      font-size: $font-size-20;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.25;
      margin-right: 1rem;
      margin-left: 1rem;
      width: max-content;
      overflow-wrap: anywhere;
    }

    &-selected {
      color: $surrogate-9;
    }
  }

  &-mobile {
    background: red;

    @include media-from(lg) {
      display: none;
    }
  }
}

.tab-bar {
  display: none;

  @include media-from(md) {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 54px 54px 0 0;
    bottom: 0;
    background-color: $surrogate-9;
    transition: left $duration-default ease, width $duration-default;
  }
}

.tab-content {
  margin-top: 2.5rem;
  width: 100%;
  position: relative;
}

.tab-content-animation-wrapper {
  left: 0;
  right: 0;
  padding: 0 1.5rem 2rem 1.5rem;

  @include media-from(md) {
    padding: 0;
  }
}

.tab-nav-wrapper {
  position: relative;
  margin: 0 0 2rem;
  min-width: 100%;
  border-bottom: 1.5px solid $surrogate-4;

  @include media-from(md) {
    border-bottom: 1px solid rgba(199, 18, 92, 0.2);
  }

  @include media-from('lg') {
    margin: 0 0 3rem;
  }

  .nav-button {
    position: absolute;
    top: 0;
    bottom: -1px;
    appearance: none;
    border: none;
    background: none;
    color: $surrogate-9;
    cursor: pointer;
    transition: transform 0.3s, opacity 0.3s, visibility 0s, color 0.3s;
    padding-inline: 0.625rem;

    &:disabled {
      color: $surrogate-4;
      pointer-events: none;

      @include media-from(md) {
        opacity: 0;
        visibility: hidden;
        transition: transform 0.3s, opacity 0.3s, visibility 0s 0.3s, color 0.3s;
      }
    }

    &.next {
      right: 0;
      padding-left: 2.25rem;

      @include media-from(md) {
        background: linear-gradient(
          90deg,
          rgba($neutral-0, 0) 0%,
          rgba($neutral-0, 0.7) 44%,
          rgba($neutral-0, 1) 100%
        );
      }

      &:hover {
        transform: translateX(4px);
      }
    }

    &.prev {
      left: 0;
      padding-right: 2.25rem;

      @include media-from(md) {
        background: linear-gradient(
          270deg,
          rgba($neutral-0, 0) 0%,
          rgba($neutral-0, 0.7) 44%,
          rgba($neutral-0, 1) 100%
        );
      }

      &:hover {
        transform: translateX(-4px);
      }
    }
  }
}
