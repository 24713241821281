@import '../../styles/index';

.split-section {
  @include media-from(xs) {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: 100%;
      margin-bottom: 2rem;

      @include media-from(md) {
        &:nth-child(odd) {
          width: calc(50% - 2rem);
        }

        &:nth-child(even) {
          width: 50%;
        }
      }

      @include media-from(lg) {
        &:nth-child(odd) {
          width: calc(100% / 3 - 2rem);
        }

        &:nth-child(even) {
          width: calc((100% / 3) * 2);
        }
      }
    }
  }
}

.preview {
  margin-bottom: $space-between-sections;
}
