@import '../../../styles/index';

.base {
  margin-bottom: 2rem;

  @include media-from(sm) {
    margin-bottom: 2.25rem;
  }
}

.name {
  font-size: $font-size-18;
  text-align: center;
  line-height: 1.3;
  margin: 0.9rem 0 1.15rem 0;

  @include media-from(sm) {
    font-size: $font-size-24;
    line-height: 1.6;
    margin: 1rem 0 1.25rem 0;
  }
}
