@import '../../styles/index';

.base {
  margin: 0;
  border-bottom: 1.5px solid $neutral-7;
  padding: 2rem 0;

  @include media-from(sm) {
    padding: 3rem 0;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.headline {
  color: $neutral-12;
  font-size: $font-size-22;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0;
}

.sub-text {
  color: $neutral-12;
  font-family: 'Euclid Enercity';
  font-size: $font-size-18;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.5;
  margin: 0.5rem 0 1.5rem;

  @include media-from(sm) {
    margin-bottom: 2rem;
  }
}

.dropdown {
  margin: 1rem 0 2rem;
}
