@import '../../styles/index';

.base {
  position: relative;
  overflow: hidden;
  margin: 0 -1rem;
  @include media-from(sm) {
    margin: 0;
  }
}

.overlay {
  display: none;
  &.is-visible {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.image {
  height: 100%;
  @include media-from(sm) {
    height: auto;
    width: 100%;
  }
}

.image-area {
  height: 375px;
  width: 100%;
  position: relative;
  overflow: hidden;
  @include media-from(xs) {
    height: 500px;
  }
  @include media-from(sm) {
    height: auto;
  }
}

.tooltip-slider {
  display: inline-flex;
  padding: 1.5rem 0;
  margin-left: 1rem;
  user-select: none;
  cursor: default;
  > * {
    margin-right: 1rem;
  }

  > a div {
    height: 100%;
    min-height: 11rem;
    width: 17.625rem;
  }

  &.small-mobile {
    > a div {
      min-height: 14rem;
      width: 15.625rem;
    }
  }
  @include media-from(sm) {
    margin-left: 0;
  }
}
