@import '../../../styles/magazine-index';

.base {
  margin: 0;
}

.date {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 1.5;
  color: $neutral-12;
  margin-bottom: 1.25rem;

  @include media-from(sm) {
    margin-bottom: 1.5rem;
  }
}
