@import '../../styles/index';

.base {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.content {
  margin-top: 3rem;
  padding: 0 1rem;
  width: 100%;
  max-width: calc(720px + 2rem);

  @include media-from(sm) {
    margin-top: 5rem;
  }
}
