@import '../../../styles/index';

.base {
  text-align: center;

  @include media-from(sm) {
    margin-bottom: 1rem;
  }
}

.price {
  display: inline-block;
  background-image: $gradient-enercity;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: $font-size-44;
  line-height: 0.9;

  @include media-from(sm) {
    font-size: $font-size-64;
  }
}

.small {
  font-size: $font-size-20;

  @include media-from(sm) {
    font-size: $font-size-44;
  }
}

.sub-text {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 3;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-18;
  }
}
