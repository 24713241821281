@use 'sass:math';

@import '../../styles/index';

.title-wrapper {
  color: $neutral-0;
  margin-bottom: 1rem;
  padding-left: calc(0.5rem + env(safe-area-inset-left));
  padding-right: calc(0.5rem + env(safe-area-inset-right));
  text-align: center;

  @include media-from(sm) {
    margin-bottom: 2.5rem;
  }

  @include media-from(md) {
    margin-bottom: 5rem;
  }
}

@function getAutoSize($maxInRem, $breakpoint) {
  @return calc(
    #{strip-unit($maxInRem) * 16} / #{strip-unit(
        map-get($breakpoints, $breakpoint)
      )} * 100vw
  );
}

.title {
  font-size: clamp(1.5rem, getAutoSize(3rem, 'md'), 3rem);
  font-weight: $font-weight-bold;
  line-height: 1;
  margin: 0;
  text-shadow:
    0 10px 100px rgba(0, 0, 0, 0.6),
    0 10px 200px rgba(0, 0, 0, 0.4);

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.subtitle {
  font-size: clamp(1rem, getAutoSize(2rem, 'md'), 2rem);
  font-weight: $font-weight-medium;
  line-height: 1.125;
  margin: 0;
  text-shadow: 0 0 70px rgba(0, 0, 0, 1);

  &:not(:last-child) {
    margin-bottom: 1rem;

    @include media-from(sm) {
      margin-bottom: 1.5rem;
    }

    @include media-from(md) {
      margin-bottom: 2.5rem;
    }
  }
}
