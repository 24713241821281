@import '../../../styles/index';

.base {
  text-align: center;
  margin-bottom: 1rem;

  @include media-from(sm) {
    margin-bottom: 0.85rem;
  }
}

.name {
  margin: 0 auto;
  font-size: $font-size-20;
  line-height: 1.2;
  max-width: 17ch;

  @include media-from(sm) {
    font-size: $font-size-24;
    line-height: 1.6;
  }
}

.description {
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  line-height: 2.25;

  @include media-from(sm) {
    font-size: $font-size-16;
  }
}
