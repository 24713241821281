@import '../../styles/index';

$sizeValues: 0 0.5 1 1.5 2 2.5 3 4 5 6;
$sizeKeys: xs sm md lg;

.base {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

@each $value in $sizeValues {
  .defaultSize#{$value * 10} {
    height: #{$value}rem;
  }
}

@each $sizeKey in $sizeKeys {
  @include media-from($sizeKey) {
    @each $value in $sizeValues {
      .#{$sizeKey}#{$value * 10} {
        height: #{$value}rem;
      }
    }
  }
}
