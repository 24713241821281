.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
